import { Box, Button, Typography } from "@mui/material";
import { Link, useLocation, Navigate, useNavigate } from "react-router-dom";
import useBreakpoint from "../../../hooks/useBreakpoint";
import MeadowContainer from "../../container";
import { useState, useEffect, useCallback } from "react";
import { GREY_100, GREY_800, RED_500, WHITE_000 } from "../../../theme/colors";
import AddIcon from "@mui/icons-material/Add";
import { AccessCard } from "./AccessCard";
import { AccountAccessResponse } from "../Student.model";
import PoweredByMeadow from "../../footer/PoweredByMeadow";
import { PaymentsStudent } from "../../../reducers/PaymentsReducer";
import { AuthService } from "../../../services/AuthService";
import LoadingDialog from "../../modal/LoadingDialog";
import addNewMemberSvg from "../../../assets/add_new_member.svg";
import { InfoIcon } from "../../icons/InfoIcon";
import * as Sentry from "@sentry/react";

export const ManageAccountAccess = () => {
    const { isMobile } = useBreakpoint();
    const location = useLocation();
    const navigate = useNavigate();
    const [student] = useState<PaymentsStudent | null>(location.state?.data);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [authService] = useState(new AuthService());
    const [accountAccessList, setAccountAccessList] = useState<AccountAccessResponse[]>([]);
    const [revokeAccessError, setRevokeAccessError] = useState<string | undefined>(undefined);

    const fetchAccessChallenges = useCallback(async () => {
        if (!student) {
            setIsLoading(false);
            return;
        }

        try {
            const accessChallenges = await authService.getAccountAccessChallenges(
                student.studentId
            );
            setAccountAccessList(accessChallenges);
        } catch (error) {
            Sentry.captureException(error);
        } finally {
            setIsLoading(false);
        }
    }, [authService, student]);

    useEffect(() => {
        if (!student) {
            navigate("/billing");
        }

        fetchAccessChallenges();
    }, [fetchAccessChallenges]);

    const innerContainerSx = {
        width: isMobile ? "100%" : "24.5rem",
    };
    const bodyWrapperSx = {
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: isMobile ? "1rem" : "1rem 0",
    };

    const mobileOverrideSx = {
        backgroundColor: GREY_800,
    };

    return (
        <MeadowContainer
            showSimpleHeader={true}
            simpleHeaderText="Account access"
            simpleHeaderOverrideStyles={isMobile ? {} : mobileOverrideSx}
            lightSimpleHeader
            noPadding
            fullWidth
            sx={{
                backgroundColor: GREY_800,
                alignItems: "center",
                paddingBottom: "1rem",
            }}
        >
            <Box sx={innerContainerSx}>
                {isLoading ? (
                    <LoadingDialog open={isLoading} />
                ) : (
                    student && (
                        <>
                            <Box
                                sx={{
                                    border: `1px solid ${GREY_100}`,
                                    borderLeft: "none",
                                    borderRight: "none",
                                    padding: isMobile ? "1rem" : "1rem 0",
                                    backgroundColor: isMobile ? WHITE_000 : "unset",
                                }}
                            >
                                <Typography sx={{ fontWeight: 500 }}>
                                    {student.firstName} {student.lastName}
                                </Typography>
                                <Typography>
                                    Student account:{" "}
                                    <span style={{ fontWeight: 500 }}>{student.accountNumber}</span>
                                </Typography>
                            </Box>
                            <Box sx={bodyWrapperSx}>
                                <Link
                                    style={{ textDecoration: "none" }}
                                    to={"/access/grant"}
                                    state={{ data: student }}
                                >
                                    <Button sx={{ maxWidth: "auto" }} variant="secondary">
                                        <AddIcon />
                                        Add new member
                                    </Button>
                                </Link>
                                {revokeAccessError && (
                                    <Box
                                        sx={{
                                            display: "flex",
                                        }}
                                    >
                                        <InfoIcon fill={RED_500} />
                                        <Typography variant="body2" sx={{ color: RED_500 }}>
                                            {revokeAccessError}
                                        </Typography>
                                    </Box>
                                )}
                                {accountAccessList.length > 0 ? (
                                    accountAccessList.map(
                                        (access: AccountAccessResponse, index: number) => {
                                            return (
                                                <AccessCard
                                                    key={index}
                                                    access={access}
                                                    studentId={student.studentId}
                                                    onRevokeAccess={fetchAccessChallenges}
                                                    setRevokeAccessError={setRevokeAccessError}
                                                />
                                            );
                                        }
                                    )
                                ) : (
                                    <img
                                        style={{
                                            maxWidth: "8.25rem",
                                            margin: "auto",
                                            paddingTop: "2rem",
                                        }}
                                        src={addNewMemberSvg}
                                        alt="Add new member"
                                    />
                                )}
                            </Box>
                        </>
                    )
                )}
            </Box>
            <PoweredByMeadow />
        </MeadowContainer>
    );
};
