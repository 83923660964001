import { BillingTerm } from "../components/billing/Billing.model";
import { Student } from "../components/billing/Student.model";
import { IMeadowHttpFactory, meadowHttpFactory } from "./meadowHttpFactory";

export type IBillingService = {
    getBillingData(): Promise<Student[]>;
    getBillingDetails(studentId: number): Promise<BillingTerm[]>;
};

export class BillingService implements IBillingService {
    private http: IMeadowHttpFactory;
    public constructor(http?: IMeadowHttpFactory) {
        this.http = http || meadowHttpFactory({});
    }

    public async getBillingData(): Promise<Student[]> {
        const result = await this.http.get("/canonical/billing");

        return result.data;
    }

    public async getBillingDetails(studentId: number): Promise<BillingTerm[]> {
        const result = await this.http.get(`/canonical/billing/student/${studentId}/details`);

        return result.data;
    }
}
