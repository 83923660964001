import MeadowContainer from "../container";
import creditCard from "../../assets/credit_card.svg";
import { BLACK_000, GREY_800, GREY_500, BLUE_500 } from "../../theme/colors";
import { Button, Box, Card, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useBreakpoint from "../../hooks/useBreakpoint";
import { BankIcon } from "../icons/Bank";
import { useContext, useEffect, useState } from "react";
import { PaymentContext } from "../../providers/PaymentsProvider";
import { GetSchoolResponse } from "../../services/School.model";
import { formatBasisPoints } from "../billing/utilities";
import CancelDialog from "./CancelDialog";
import useAmountToPay from "../../hooks/useAmountToPay";

export default function PaymentMethod() {
    const navigate = useNavigate();
    const { isMobile } = useBreakpoint();
    const { getStudentSchool } = useContext(PaymentContext);
    const [isCancel, setIsCancel] = useState<boolean>(false);
    const [school, setSchool] = useState<GetSchoolResponse | null>(null);
    const innerContainerSx = {
        width: isMobile ? "100%" : "24.5rem",
    };

    const noCreditCardFees =
        !school?.cardFeeDomesticBasisPoints && !school?.cardFeeInternationalBasisPoints;

    useAmountToPay("payment-method");

    useEffect(() => {
        const studentSchool = getStudentSchool();

        setSchool(studentSchool);
    }, []);

    const cardSx = {
        display: "flex",
        justifyContent: "space-between",
        gap: "0.5rem",
        padding: "1.25rem 1rem",
        marginBottom: "1rem",
        boxShadow: "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
        borderRadius: "0.5rem",
        border: "1.6px solid transparent",
        "&:hover": {
            cursor: "pointer",
            border: `1.6px solid ${BLUE_500}`,
        },
    };

    const leftCardBoxSx = {
        display: "flex",
        alignItems: "center",
        flexBasis: 0,
        flexGrow: 1,
    };

    const rightCardBoxSx = {
        display: "flex",
        flexDirection: "column",
        flexBasis: 0,
        flexGrow: 1,
    };

    const methodNameSx = {
        fontWeight: 500,
        fontSize: "1rem",
        lineHeight: "1.5rem",
        color: BLACK_000,
        marginLeft: "0.5rem",
    };

    const methodDetailHeadingSx = {
        fontWeight: 500,
        fontSize: "0.875rem",
        lineHeight: "1.5rem",
        color: GREY_500,
    };

    const methodDetailTextSx = {
        fontWeight: 400,
        fontSize: "0.75rem",
        lineHeight: "1rem",
        color: GREY_500,
    };

    const pageTitle = "Select payment method";

    return (
        <MeadowContainer
            fullWidth
            showSimpleHeader={true}
            simpleHeaderText={pageTitle}
            sx={{
                backgroundColor: GREY_800,
                alignItems: "center",
            }}
        >
            <Box sx={innerContainerSx}>
                <Card sx={cardSx} onClick={() => navigate("/payment/bank")}>
                    <Box sx={leftCardBoxSx}>
                        <BankIcon />
                        <Typography sx={methodNameSx}>Bank account</Typography>
                    </Box>
                    <Box sx={rightCardBoxSx}>
                        <Typography sx={methodDetailHeadingSx}>No fee</Typography>
                    </Box>
                </Card>
                <Card sx={cardSx} onClick={() => navigate("/payment/card")}>
                    <Box sx={leftCardBoxSx}>
                        <img style={{ height: "1.5rem", width: "1.5rem" }} src={creditCard} />
                        <Typography sx={methodNameSx}>Credit card</Typography>
                    </Box>
                    <Box sx={rightCardBoxSx}>
                        {noCreditCardFees ? (
                            <Typography sx={methodDetailHeadingSx}>No fee</Typography>
                        ) : (
                            <>
                                <Typography sx={methodDetailHeadingSx}>Service fee</Typography>
                                {school && school.cardFeeDomesticBasisPoints > 0 && (
                                    <Typography sx={methodDetailTextSx}>
                                        Domestic:{" "}
                                        {formatBasisPoints(school.cardFeeDomesticBasisPoints)}
                                    </Typography>
                                )}

                                {school && school.cardFeeInternationalBasisPoints > 0 && (
                                    <Typography sx={methodDetailTextSx}>
                                        International:{" "}
                                        {formatBasisPoints(school.cardFeeInternationalBasisPoints)}
                                    </Typography>
                                )}
                            </>
                        )}
                    </Box>
                </Card>
                <Button
                    sx={{
                        marginTop: "0.5rem",
                    }}
                    onClick={() => setIsCancel(true)}
                    variant="secondary"
                >
                    Cancel
                </Button>
            </Box>
            <>{isCancel && <CancelDialog open={isCancel} onClose={() => setIsCancel(false)} />}</>
        </MeadowContainer>
    );
}
